import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";
import {checkData} from "../../helper";

function Value({data}) {
    return (
        <div className="tekrevol_value p-100" id="values">
            <Container>
                <Row>
                    <Col xs={12} md={4} lg={4} xl={4} xxl={4} className="my-auto">
                        <h2>{checkData(data, 'value_title')}</h2>
                        <div
                            dangerouslySetInnerHTML={{__html: checkData(data, 'value_description')}}/>
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={8} xxl={8} className="text-center mt-auto">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'value_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'value_title')}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Value;